import React, { useState, useEffect } from 'react';

import { Spin, Table, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import SideNav from '../../utils/side_nav';
import TopNav from '../../utils/top_nav';
import axios from '../../utils/axiosCall';

const NewCategory = () => {

    const [categoryData, setCategoryData] = useState([]);
    const [current, setCurrent] = useState(1);

    const [errorMessage, setErrorMessage] = useState('');
    const [spinnerStatus, setSpinnerStatus] = useState(true);

    useEffect(() => {
        axios('/category/all')
            .then(categoryData => {
                if (categoryData.data.statusMessage === "success") {
                    setCategoryData(categoryData.data.message);
                    setSpinnerStatus(false);
                } else {
                    setErrorMessage(categoryData.data.summary)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                setErrorMessage('An error occurred while fetching category data. Please reload page to try again');
                setSpinnerStatus(false);
            })
    }, [])

    const columns = [
        {
            title: '',
            dataIndex: 'sn'
        },
        {
            title: 'Category Name',
            className: 'column-money',
            dataIndex: 'categoryName',
        },
        {
            title: 'Products List',
            className: 'column-money',
            dataIndex: 'productsList',
        },
        {
            title: 'Date Added',
            className: 'column-money',
            dataIndex: 'dateAdded',
        }
    ];
    const data = categoryData.map((category, index) => {
        return {
            sn: index + 1,
            key: index,
            categoryName: category.categoryName,
            productsList: category.Products.length,
            dateAdded: DateTime.fromISO(category.createdAt).toLocaleString(DateTime.DATE_HUGE),
        }
    })

    const pageSize = 10;

    const getData = (current, pageSize) => {
        // Normally you should get the data from the server
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    // Custom pagination component
    const MyPagination = ({ total, onChange, current }) => {
        return (
            <Pagination
                onChange={onChange}
                total={total}
                current={current}
                pageSize={pageSize}
            />
        );
    };

    return (
        <div>
            <Spin spinning={spinnerStatus}>
                <div className="portal_page">
                    <div>
                        <SideNav />
                    </div>
                    <div className="main_content">
                        <TopNav currentPage={"All Categories"}
                            buttonTitle={"Add New Category"}
                            buttonLink={"/category/new"}
                            currentPageInfo={"All Categories under Products"} />
                        <div>
                            <div className="main_compartment">
                                <div className="main_compartment_content_display">
                                    <div className="main_compartment_content">
                                        <div className="contain">
                                            {
                                                !errorMessage ?
                                                    <div>
                                                        <Table
                                                            columns={columns}
                                                            dataSource={getData(current, pageSize)}
                                                            bordered
                                                        />
                                                        <div style={{ marginTop: '3%' }}></div>
                                                        <div style={{ float: 'right' }}>
                                                            <MyPagination
                                                                total={data.length}
                                                                current={current}
                                                                onChange={setCurrent}
                                                            />
                                                        </div>
                                                        <div style={{ marginTop: '3%', clear: 'both' }}></div>
                                                    </div>
                                                    :
                                                    <div className="bigErrorData">
                                                        <div>{errorMessage}</div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default NewCategory;