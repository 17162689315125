import React, { useEffect, useState } from 'react';
import SideNav from '../../utils/side_nav';
import TopNav from '../../utils/top_nav';

import { Input, Spin, message, Upload, Select } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../utils/axiosCall';
import FileUpload from '../products/imageupload';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// import Options from '../../common_files/questions';

const NewCategory = () => {

    const params = useParams();
    const [errorMessage, setErrorMessage] = useState('');
    const [spinnerStatus, setSpinnerStatus] = useState(false);
    const [categoryData, setCategoryData] = useState({});
    const [imageToSend, setImageToSend] = useState(null);

    const navigate = useNavigate();

    const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    });

    useEffect(() => {
        axios(`/products/portfolio/all-portfolio-companies/${params.id}`)
            .then(categoryData => {
                if (categoryData.data.statusMessage === "success") {
                    setCategoryData(categoryData.data.message);
                    setValue('companyName', `${categoryData.data.message.companyName} - ${categoryData.data.message.portfolioCategory.categoryName}`)
                    setSpinnerStatus(false);
                } else {
                    setErrorMessage(categoryData.data.summary)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                setErrorMessage('An error occurred while fetching category data. Please reload page to try again');
                setSpinnerStatus(false);
            })
    }, [])

    const savePortfolio = e => {
        setErrorMessage('');
        setSpinnerStatus(true);
        const uploadData = new FormData();
        uploadData.append('productImage', imageToSend);
        uploadData.append('companyId', categoryData.id);
        axios.post('/products/portfolio/add-company-image', uploadData)
            .then(categorySaved => {
                if (categorySaved.data.statusMessage === "success") {
                    navigate('/');
                } else {
                    setErrorMessage(categorySaved.data.summary)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                setErrorMessage('An error occurred while saving category data. Please try again')
                setSpinnerStatus(false);
            })
    }

    const receiveImageUploadFile = e => {
        setImageToSend(e);
    }

    return (
        <div>
            <Spin spinning={spinnerStatus}>
                <div className="portal_page">
                    <div>
                        <SideNav />
                    </div>
                    <div className="main_content">
                        <TopNav currentPage={"Add Image"}
                            buttonTitle={"Add New Company"}
                            buttonLink={"/portfolio/add"}
                            currentPageInfo={"Add New Image for Company"} />
                        <div>
                            <div className="main_compartment">
                                <div className="main_compartment_content_display">
                                    <div className="main_compartment_content">
                                        <div className="form_display">
                                            <form onSubmit={handleSubmit(savePortfolio)}>
                                                <div>
                                                    <div className="form_heading_text">
                                                        <h3 className="big_site_text">Add New Image</h3>
                                                    </div>
                                                    <div>
                                                        {
                                                            errorMessage ?
                                                                <p className="errorMessage">{errorMessage}</p> : ''
                                                        }
                                                    </div>
                                                    <div className="form-group mt-5">
                                                        <label htmlFor="companyName">Company Name</label>
                                                        <Controller control={control} defaultValue="" name="companyName"
                                                            render={({ field }) => (
                                                                <Input {...field} id="companyName" disabled
                                                                    type="text" style={{ height: '3rem' }} />
                                                            )
                                                            } />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="productImage">Product Image</label>
                                                        <FileUpload imageUploadFunction={receiveImageUploadFile} />
                                                    </div>
                                                    <div>
                                                        <div style={{ marginTop: '5%' }}></div>
                                                        <button className="bg_red">Save Image</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default NewCategory;