import React, { useState, useEffect } from 'react';
import SideNav from '../../utils/side_nav';
import TopNav from '../../utils/top_nav';

import { Input, Spin, message, Upload, Select } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axiosCall';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import FileUpload from './imageupload';

// import Options from '../../common_files/questions';

const NewProducts = () => {

    const [imageToSend, setImageToSend] = useState(null);
    const [categoryData, setCategoryData] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');
    const [spinnerStatus, setSpinnerStatus] = useState(false);

    const navigate = useNavigate();

    const { TextArea } = Input;

    useEffect(() => {
        axios('/category/all')
            .then(categoryData => {
                if (categoryData.data.statusMessage === "success") {
                    setCategoryData(categoryData.data.message);
                    setSpinnerStatus(false);
                } else {
                    setErrorMessage(categoryData.data.summary)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                setErrorMessage('An error occurred while fetching category data. Please reload page to try again');
                setSpinnerStatus(false);
            })
    }, [])

    const resolveForm = yup.object().shape({
        productName: yup.string().required('Please enter product name'),
        productDimension: yup.string().required('Please enter product dimension'),
        productDescription: yup.string().required('Please enter product description'),
        productCategory: yup.string().required('Please enter product category'),
        productPrice: yup.string().required('Please enter product price')
    })

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(resolveForm)
    });

    const saveCategory = e => {
        setErrorMessage('');
        setSpinnerStatus(true);
        let formInfo = new FormData();
        formInfo.append('productImage', imageToSend);
        formInfo.append('productName', e.productName);
        formInfo.append('productPrice', e.productPrice);
        formInfo.append('productCategory', e.productCategory);
        formInfo.append('productDimension', e.productDimension);
        formInfo.append('productDescription', e.productDescription);
        axios.post('/products/new', formInfo).then(productSaved => {
            if (productSaved.data.statusMessage === "success") {
                navigate('/');
            } else {
                setErrorMessage(productSaved.data.summary)
                setSpinnerStatus(false);
            }
        })
            .catch(err => {
                setErrorMessage('An error occurred while saving category data. Please try again')
                setSpinnerStatus(false);
            })
    }

    const receiveImageUploadFile = e => {
        setImageToSend(e);
    }

    return (
        <div>
            <Spin spinning={spinnerStatus}>
                <div className="portal_page">
                    <div>
                        <SideNav />
                    </div>
                    <div className="main_content">
                        <TopNav currentPage={"New Product"}
                            buttonTitle={"See All Products"}
                            buttonLink={"/products/all"}
                            currentPageInfo={"Add New Product"} />
                        <div>
                            <div className="main_compartment">
                                <div className="main_compartment_content_display">
                                    <div className="main_compartment_content">
                                        <div className="form_display">
                                            <form onSubmit={handleSubmit(saveCategory)}>
                                                <div>
                                                    <div className="form_heading_text">
                                                        <h3 className="big_site_text">Add New Product</h3>
                                                    </div>
                                                    <div>
                                                        {
                                                            errorMessage ?
                                                                <p className="errorMessage">{errorMessage}</p> : ''
                                                        }
                                                    </div>
                                                    <div className="form-group mt-5">
                                                        <label htmlFor="productImage">Product Image</label>
                                                        <FileUpload imageUploadFunction={receiveImageUploadFile} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="productName">Product Name</label>
                                                        <Controller control={control} defaultValue="" name="productName"
                                                            render={({ field }) => (
                                                                <Input {...field} id="productName"
                                                                    type="text" style={{ height: '3rem' }} />
                                                            )
                                                            } />

                                                        {errors.productName && <p className="errorMessage">{errors.productName.message}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="productPrice">Product Price</label>
                                                        <Controller control={control} defaultValue="" name="productPrice"
                                                            render={({ field }) => (
                                                                <Input {...field} id="productPrice"
                                                                    type="text" style={{ height: '3rem' }} />
                                                            )
                                                            } />

                                                        {errors.productPrice && <p className="errorMessage">{errors.productPrice.message}</p>}
                                                    </div>
                                                    <div className="form_flex_2">
                                                        <div className="form-group">
                                                            <label htmlFor="productCategory">Product Category</label>
                                                            <Controller control={control} defaultValue="" name="productCategory"
                                                                render={({ field }) => (
                                                                    <Select {...field} id="productCategory"
                                                                        type="text" style={{ height: '3rem', width: '100%', display: 'block' }}>
                                                                        {
                                                                            categoryData.map((category, index) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        key={index}
                                                                                        value={category.id}>{category.categoryName}</Select.Option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Select>
                                                                )
                                                                } />

                                                            {errors.productCategory && <p className="errorMessage">{errors.productCategory.message}</p>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="productDimension">Product Dimension</label>
                                                            <Controller control={control} defaultValue="" name="productDimension"
                                                                render={({ field }) => (
                                                                    <Input {...field} id="productDimension"
                                                                        type="text" style={{ height: '3rem' }} />
                                                                )
                                                                } />

                                                            {errors.productDimension && <p className="errorMessage">{errors.productDimension.message}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="productDescription">Product Description</label>
                                                        <Controller control={control} defaultValue="" name="productDescription"
                                                            render={({ field }) => (
                                                                <Input.TextArea {...field} id="productDescription"
                                                                    type="text" rows={4} />
                                                            )
                                                            } />

                                                        {errors.productDescription && <p className="errorMessage">{errors.productDescription.message}</p>}
                                                    </div>
                                                    <div>
                                                        <div style={{ marginTop: '5%' }}></div>
                                                        <button className="bg_red">Save Product</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default NewProducts;