import React, { useState } from "react";
import { Input, Button, Select, Upload, message, Table, Spin, notification, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const FileUpload = props => {

    const [imageUrl, setImageUrl] = useState(null);
    const [imageToSend, setImageToSend] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageToUse, setImageToUse] = useState('');

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            setImageUrl(null);
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
            setImageUrl(null);
        }
        return isJpgOrPng && isLt2M && false;
    }
    const handleChange = info => {
        let error = "";
        const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
        if (!isJpgOrPng) {
            error = 'You can only upload JPG/PNG file!';
        }
        const isLt2M = info.file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            error = 'Image must smaller than 2MB!';
        }
        if (!error) {
            if (info.file) {
                // Get this url from response in real world.
                getBase64(info.file, imageUrl =>
                    setImageUrl(imageUrl),
                    setLoading(false),
                );
                const reader = new FileReader();
                setImageToUse(info.file);
                props.imageUploadFunction(info.file)
                reader.readAsDataURL(info.file);
                reader.addEventListener('load', () => {
                    setImageToSend(reader.result);
                });
            }
        } else {
            setImageToSend(null);
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )

    return (
        <div>
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                multiple={true}
            >
                {imageUrl ? <img src={imageUrl} alt="avatar" className="adminCourseUpload" /> : uploadButton}
            </Upload>
        </div>
    )

}

export default FileUpload;