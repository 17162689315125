import React from 'react';
import { Link } from 'react-router-dom';

import AppRoute from './routes';

import Logo from '../assets/images/logo.png';

const SideNav = () => {
    return (
        <div className="side_nav">
            <div className="nav_compartment bigger">
                <img src={Logo} className="logo" alt="logo" />
            </div>
            <div className="nav_compartment">
                <h4>User Management</h4>
                <ul>
                    <li><Link to="/"><i className="uil uil-create-dashboard"></i> Dashboard</Link></li>
                    <li><Link to={AppRoute.all_users}><i className="uil uil-create-dashboard"></i> All Users</Link></li>
                    <li><Link to={AppRoute.all_orders}><i className="uil uil-create-dashboard"></i> All Orders</Link></li>
                </ul>
            </div>
            <div className="nav_compartment">
                <h4>Products</h4>
                <ul>
                    <li><Link to={AppRoute.new_category}><i className="uil uil-create-dashboard"></i> Add Product Category</Link></li>
                    <li><Link to={AppRoute.all_category}><i className="uil uil-create-dashboard"></i> All Product Categories</Link></li>
                    <li><Link to={AppRoute.all_products}><i className="uil uil-create-dashboard"></i> All Products</Link></li>
                    <li><Link to={AppRoute.new_products}><i className="uil uil-plus-circle"></i> Add New Product</Link></li>
                    <li><Link to={AppRoute.add_portfolio}><i className="uil uil-create-dashboard"></i> Add Portfolio Company</Link></li>
                    <li className="last_nav"><Link to={AppRoute.all_portfolio}><i className="uil uil-create-dashboard"></i> All Projects</Link></li>
                </ul>
            </div>
            {/*
            <div className="nav_compartment">
                <h4>Communities</h4>
                <ul>
                    <li><Link to="/community/addtags"><i className="uil uil-create-dashboard"></i> Add Tags</Link></li>
                    <li><Link to="/communities/categories/new"><i className="uil uil-create-dashboard"></i> Add Community Categories</Link></li>
                    <li><Link to="/communities/categories/all"><i className="uil uil-create-dashboard"></i> All Community Categories</Link></li>                    
                    <li><Link to="/communities/all"><i className="uil uil-create-dashboard"></i> All Communities</Link></li>
                    <li><Link to="/communities/new"><i className="uil uil-plus-circle"></i> Create Community</Link></li>
                    <li><Link to="/communities/hidden"><i className="uil uil-archive-alt"></i> Hidden Communities</Link></li>
                    <li><Link to="/communities/reviewsandcomments"><i className="uil uil-history-alt"></i> Reviews and Comments</Link></li>
                    <li><Link to="/communities/members"><i className="uil uil-history-alt"></i> Community Members</Link></li>
                    <li className="last_nav"><Link to="/communities/edit"><i className="uil uil-create-dashboard"></i> Edit Communities</Link></li>
                </ul>
            </div>
            <div className="nav_compartment">
                <h4>Businesses</h4>
                <ul>
                    <li><Link to="/business/addtags"><i className="uil uil-create-dashboard"></i> Add Tags</Link></li>
                    <li><Link to="/businesses/categories/new"><i className="uil uil-create-dashboard"></i> Add Business Category</Link></li>
                    <li><Link to="/businesses/categories/all"><i className="uil uil-create-dashboard"></i> All Business Categories</Link></li>
                    <li><Link to="/businesses/all"><i className="uil uil-create-dashboard"></i> All Businesses</Link></li>
                    <li><Link to="/businesses/new"><i className="uil uil-plus-circle"></i> Create Business</Link></li>
                    <li><Link to="/businesses/hidden"><i className="uil uil-history-alt"></i> Hidden Businesses</Link></li>
                    <li><Link to="/businesses/reviewsandcomments"><i className="uil uil-history-alt"></i> Reviews and Comments</Link></li>
                    <li><Link to="/businesses/edit"><i className="uil uil-create-dashboard"></i> Edit Businesses</Link></li>
                </ul>
            </div> */}
        </div>
    )
}

export default SideNav;