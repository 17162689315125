import './users.css';

import React, { useState, useEffect } from 'react';
import SideNav from '../../utils/side_nav';
import TopNav from '../../utils/top_nav';

import { Table, Spin, Pagination } from 'antd';
import { DateTime } from 'luxon';

import axios from '../../utils/axiosCall';
import { Link } from 'react-router-dom';
import Thinking from '../../assets/images/think.svg';

const AllUsers = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const [usersData, setUsersData] = useState([]);
    const [spinnerStatus, setSpinnerStatus] = useState(true);
    const [current, setCurrent] = useState(1);


    useEffect(() => {
        axios('/users/users/all', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(eventsData => {
                if (eventsData.data.statusMessage === "success") {
                    setUsersData(eventsData.data.message);
                    setSpinnerStatus(false);
                } else {
                    setErrorMessage(eventsData.data.statusMessage)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                setErrorMessage('An error occurred while fetching category data. Please reload page to try again');
                setSpinnerStatus(false);
            })
    }, [])
    const columns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'Full name',
            className: 'column-money',
            dataIndex: 'fullName',
        },
        {
            title: 'Email address',
            className: 'column-money',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Phone number',
            className: 'column-money',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Signup Date',
            className: 'column-money',
            dataIndex: 'createdAt',
        }
    ];
    const data = usersData.map((user, index) => {
        return {
            sn: index + 1,
            key: index,
            fullName: `${user.lastName} ${user.firstName}`,
            emailAddress: user.emailAddress,
            phoneNumber: user.phoneNumber,
            createdAt: DateTime.fromISO(user.createdAt).toLocaleString(DateTime.DATE_HUGE)
        }
    })

    const pageSize = 10;

    const getData = (current, pageSize) => {
        // Normally you should get the data from the server
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    // Custom pagination component
    const MyPagination = ({ total, onChange, current }) => {
        return (
            <Pagination
                onChange={onChange}
                total={total}
                current={current}
                pageSize={pageSize}
            />
        );
    };

    return (
        <div>
            <Spin spinning={spinnerStatus}>
                <div className="portal_page">
                    <div>
                        <SideNav />
                    </div>
                    <div className="main_content">
                        <TopNav currentPage={"All Users"}
                            buttonTitle={"See all orders"}
                            buttonLink={"/orders/all"}
                            currentPageInfo={"All registered users data"} />
                        <div>
                            <div className="main_compartment">
                                <div className="main_compartment_content_display">
                                    <div className="main_compartment_content">
                                        <div className="contain">
                                            {
                                                !errorMessage ?
                                                    <div className="category_display">
                                                        <Table
                                                            columns={columns}
                                                            dataSource={getData(current, pageSize)}
                                                            bordered
                                                        />
                                                        <div style={{ marginTop: '3%' }}></div>
                                                        <div style={{ float: 'right' }}>
                                                            <MyPagination
                                                                total={data.length}
                                                                current={current}
                                                                onChange={setCurrent}
                                                            />
                                                        </div>
                                                        <div style={{ marginTop: '3%' }}></div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className="page_center">
                                                            <img src={Thinking} alt="man thinking" />
                                                            <h6>Ooops! An error occurred while loading this page. Please reload to try again or contact web developer</h6>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default AllUsers;