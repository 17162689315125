const routes = {
    all_category: "/category/all",
    new_category: "/category/add",
    all_products: "/products/all",
    new_products: "/products/add",
    add_product_image: "/products/add-image",
    add_portfolio: "/portfolio/add",
    all_portfolio: "/portfolio/all",
    add_portfolio_image: "/portfolio/add-image",
    all_users: "/users/all",
    all_orders: "/orders/all",
    single_order: "/order/:orderName/:orderId",

    new_event: "/events/new",
    all_events: "/events/all",
    new_category: "/category/new",
    all_category: "/category/all",
    new_tags: "/category/new_tags",
    addadministrator: "/addadministrator",
    alladministrators: "/alladministrators",
    allusers: "/allusers",
    reviewsandcomments: "/events/reviewsandcomments",
    reviewDetails: "/events/reviews/:eventId",
    hiddenEvents: "/events/hidden"
}

export default routes;