import React, { useState } from 'react';
import SideNav from '../../utils/side_nav';
import TopNav from '../../utils/top_nav';

import { Input, Spin, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axiosCall';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// import Options from '../../common_files/questions';

const NewCategory = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const [spinnerStatus, setSpinnerStatus] = useState(false);

    const navigate = useNavigate();

    const resolveForm = yup.object().shape({
        categoryName: yup.string().required('Please enter category name')
    })

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(resolveForm)
    });

    const saveCategory = e => {
        setErrorMessage('');
        setSpinnerStatus(true);
        axios.post('/category/new', {
            categoryName: e.categoryName,
        }).then(categorySaved => {
            if (categorySaved.data.statusMessage === "success") {
                navigate('/');
            } else {
                setErrorMessage(categorySaved.data.summary)
                setSpinnerStatus(false);
            }
        })
            .catch(err => {
                setErrorMessage('An error occurred while saving category data. Please try again')
                setSpinnerStatus(false);
            })
    }

    return (
        <div>
            <Spin spinning={spinnerStatus}>
                <div className="portal_page">
                    <div>
                        <SideNav />
                    </div>
                    <div className="main_content">
                        <TopNav currentPage={"New category"}
                            buttonTitle={"See all categories"}
                            buttonLink={"/category/all"}
                            currentPageInfo={"Add a new Category for Products"} />
                        <div>
                            <div className="main_compartment">
                                <div className="main_compartment_content_display">
                                    <div className="main_compartment_content">
                                        <div className="form_display">
                                            <form onSubmit={handleSubmit(saveCategory)}>
                                                <div>
                                                    <div className="form_heading_text">
                                                        <h3 className="big_site_text">Add New Category</h3>
                                                    </div>
                                                    <div>
                                                        {
                                                            errorMessage ?
                                                                <p className="errorMessage">{errorMessage}</p> : ''
                                                        }
                                                    </div>
                                                    <div className="form-group mt-5">
                                                        <label htmlFor="categoryName">Category name</label>
                                                        <Controller control={control} defaultValue="" name="categoryName"
                                                            render={({ field }) => (
                                                                <Input {...field} id="categoryName"
                                                                    type="text" style={{ height: '3rem' }} />
                                                            )
                                                            } />

                                                        {errors.categoryName && <p className="errorMessage">{errors.categoryName.message}</p>}
                                                    </div>
                                                    <div>
                                                        <div style={{ marginTop: '5%' }}></div>
                                                        <button className="bg_red">Save Category</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default NewCategory;