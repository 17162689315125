import React, { useState, useEffect } from 'react';
import SideNav from '../../utils/side_nav';
import TopNav from '../../utils/top_nav';
import { DateTime } from 'luxon';
import { Table, Spin } from 'antd';
import { Pagination } from 'antd';

import axios from '../../utils/axiosCall';
import { Link } from 'react-router-dom';
import Thinking from '../../assets/images/think.svg';

const AllUserOrders = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const [usersData, setUsersData] = useState([]);
    const [spinnerStatus, setSpinnerStatus] = useState(true);
    const [current, setCurrent] = useState(1);

    useEffect(() => {
        axios('/products/orders/all', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(ordersData => {
                if (ordersData.data.statusMessage === "success") {
                    setUsersData(ordersData.data.message);
                    setSpinnerStatus(false);
                } else {
                    setErrorMessage(ordersData.data.statusMessage)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                setErrorMessage('An error occurred while fetching category data. Please reload page to try again');
                setSpinnerStatus(false);
            })
    }, [])
    const columns = [
        {
            title: '',
            dataIndex: 'sn'
        },
        {
            title: 'User name',
            className: 'column-money',
            dataIndex: 'fullName',
        },
        {
            title: 'Total Amount',
            className: 'column-money',
            dataIndex: 'totalAmount',
        },
        {
            title: 'Delivery date',
            className: 'column-money',
            dataIndex: 'deliveryDate',
        },
        {
            title: 'Delivery Status',
            className: 'column-money',
            dataIndex: 'deliveryStatus',
        },
        {
            title: 'Order Date',
            className: 'column-money',
            dataIndex: 'createdAt',
        },
        {
            title: '',
            className: 'column-money',
            dataIndex: 'action',
        }
    ];
    const data = usersData.map((plan, index) => {
        return {
            sn: index + 1,
            key: index,
            fullName: `${plan.fullName}`,
            totalAmount: plan.amountToPay,
            deliveryDate: plan.deliveryDate,
            createdAt: DateTime.fromISO(plan.createdAt).toLocaleString(DateTime.DATE_HUGE),
            deliveryStatus: plan.deliveryStatus,
            action: <Link to={`/order/${plan.fullName}/${plan.id}`}>View</Link>
        }
    })

    const pageSize = 10;

    const getData = (current, pageSize) => {
        // Normally you should get the data from the server
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    // Custom pagination component
    const MyPagination = ({ total, onChange, current }) => {
        return (
            <Pagination
                onChange={onChange}
                total={total}
                current={current}
                pageSize={pageSize}
            />
        );
    };

    return (
        <div>
            <Spin spinning={spinnerStatus}>
                <div className="portal_page">
                    <div>
                        <SideNav />
                    </div>
                    <div className="main_content">
                        <TopNav currentPage={"Order History"}
                            buttonTitle={"See all Products"}
                            buttonLink={"/products/all"}
                            currentPageInfo={"See and access all transaction history"} />
                        <div>
                            <div className="main_compartment">
                                <div className="main_compartment_content_display">
                                    <div className="main_compartment_content">
                                        <div className="contain">
                                            {
                                                !errorMessage ?
                                                    <div className="category_display">
                                                        <Table
                                                            columns={columns}
                                                            dataSource={getData(current, pageSize)}
                                                            bordered
                                                        />
                                                        <div style={{ marginTop: '3%' }}></div>
                                                        <div style={{ float: 'right' }}>
                                                            <MyPagination
                                                                total={data.length}
                                                                current={current}
                                                                onChange={setCurrent}
                                                            />
                                                        </div>
                                                        <div style={{ marginTop: '3%', clear: 'both' }}></div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className="page_center">
                                                            <img src={Thinking} alt="man thinking" />
                                                            <h6>Ooops! An error occurred while loading this page. Please reload to try again or contact web developer</h6>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default AllUserOrders;