import React, { useState, useEffect } from 'react';
import SideNav from '../../utils/side_nav';
import TopNav from '../../utils/top_nav';
import { DateTime } from 'luxon';
import { Table, Spin } from 'antd';
import NumberFormat from 'react-number-format';

import axios from '../../utils/axiosCall';
import { Link } from 'react-router-dom';
import Thinking from '../../assets/images/think.svg';

const AllUserOrders = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const [ordersData, setOrdersData] = useState([]);
    const [portfolioData, setPortfolioData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [spinnerStatus, setSpinnerStatus] = useState(true);
    const [current] = useState(1);
    const [userCurrent] = useState(1);
    const [productsBox, setProductsBox] = useState([]);

    useEffect(() => {
        axios('/products/orders/all', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(ordersData => {
                if (ordersData.data.statusMessage === "success") {
                    setOrdersData(ordersData.data.message);
                    setSpinnerStatus(false);
                } else {
                    setErrorMessage(ordersData.data.statusMessage)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                console.log(err)
                setErrorMessage('An error occurred while fetching category data. Please reload page to try again');
                setSpinnerStatus(false);
            })
    }, [])
    useEffect(() => {
        axios('/products/all', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(ordersData => {
                if (ordersData.data.statusMessage === "success") {
                    setProductsBox(ordersData.data.message);
                    setSpinnerStatus(false);
                } else {
                    setErrorMessage(ordersData.data.statusMessage)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                console.log(err)
                setErrorMessage('An error occurred while fetching category data. Please reload page to try again');
                setSpinnerStatus(false);
            })
    }, [])
    useEffect(() => {
        axios('/users/users/all', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(eventsData => {
                if (eventsData.data.statusMessage === "success") {
                    setUsersData(eventsData.data.message);
                    setSpinnerStatus(false);
                } else {
                    setErrorMessage(eventsData.data.statusMessage)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                setErrorMessage('An error occurred while fetching category data. Please reload page to try again');
                setSpinnerStatus(false);
            })
    }, [])
    useEffect(() => {
        axios('/products/portfolio/all-portfolio-companies')
            .then(categoryData => {
                if (categoryData.data.statusMessage === "success") {
                    setPortfolioData(categoryData.data.message);
                    setSpinnerStatus(false);
                } else {
                    setErrorMessage(categoryData.data.summary)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                setErrorMessage('An error occurred while fetching category data. Please reload page to try again');
                setSpinnerStatus(false);
            })
    }, [])

    const columns = [
        {
            title: '',
            dataIndex: 'sn'
        },
        {
            title: 'User name',
            className: 'column-money',
            dataIndex: 'fullName',
        },
        {
            title: 'Total Amount',
            className: 'column-money',
            dataIndex: 'totalAmount',
        },
        {
            title: 'Delivery date',
            className: 'column-money',
            dataIndex: 'deliveryDate',
        },
        {
            title: 'Delivery Status',
            className: 'column-money',
            dataIndex: 'deliveryStatus',
        },
        {
            title: 'Order Date',
            className: 'column-money',
            dataIndex: 'createdAt',
        }
    ];

    const data = ordersData.map((plan, index) => {
        return {
            sn: index + 1,
            key: index,
            fullName: `${plan.fullName}`,
            totalAmount: <p style={{ marginBottom: 0 }} className="furniture_amount"><span className="furniture_amount_currency">NGN </span>
                <span className="new_product_amount"><NumberFormat
                    value={(+plan.amountToPay).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span>
            </p>,
            deliveryDate: plan.deliveryDate,
            createdAt: DateTime.fromISO(plan.createdAt).toLocaleString(DateTime.DATE_HUGE),
            deliveryStatus: plan.deliveryStatus,
        }
    })

    const pageSize = 5;

    const getData = (current, pageSize) => {
        // Normally you should get the data from the server
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const userColumns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'Full name',
            className: 'column-money',
            dataIndex: 'fullName',
        },
        {
            title: 'Email address',
            className: 'column-money',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Phone number',
            className: 'column-money',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Signup Date',
            className: 'column-money',
            dataIndex: 'createdAt',
        }
    ];

    const userData = usersData.map((user, index) => {
        return {
            sn: index + 1,
            key: index,
            fullName: `${user.lastName} ${user.firstName}`,
            emailAddress: user.emailAddress,
            phoneNumber: user.phoneNumber,
            createdAt: DateTime.fromISO(user.createdAt).toLocaleString(DateTime.DATE_HUGE)
        }
    })

    const userPageSize = 10;

    const userGetData = (current, pageSize) => {
        // Normally you should get the data from the server
        return userData.slice((current - 1) * pageSize, current * pageSize);
    };

    return (
        <div>
            <Spin spinning={spinnerStatus}>
                <div className="portal_page">
                    <div>
                        <SideNav />
                    </div>
                    <div className="main_content">
                        <TopNav currentPage={"Admin Dashboard"}
                            buttonTitle={"See all orders"}
                            buttonLink={"/orders/all"}
                            currentPageInfo={"Hello there, Welcome Home!"} />
                        <div>
                            <div className="main_compartment">
                                <div className="main_compartment_content_display">
                                    <div className="main_compartment_content">
                                        <div className="contain">
                                            <div className="order-summary-data">
                                                <div className="grid_4">
                                                    <div className="summary-box bg-first">
                                                        <h2>{userData.length}</h2>
                                                        <p>Users</p>
                                                    </div>
                                                    <div className="summary-box bg-second">
                                                        <h2>{productsBox.length}</h2>
                                                        <p>Products</p>
                                                    </div>
                                                    <div className="summary-box bg-third">
                                                        <h2>{ordersData.length}</h2>
                                                        <p>Orders</p>
                                                    </div>
                                                    <div className="summary-box bg-fourth">
                                                        <h2>{portfolioData.length}</h2>
                                                        <p>Portfolio Companies</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                !errorMessage ?
                                                    <div className="category_display">
                                                        <h3 style={{ marginBottom: 0 }}>Order Data</h3>
                                                        <Table
                                                            columns={columns}
                                                            dataSource={getData(current, pageSize)}
                                                            bordered
                                                        />
                                                        <div style={{ marginTop: '3%' }}></div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className="page_center">
                                                            <img src={Thinking} alt="man thinking" />
                                                            <h6>Ooops! An error occurred while loading this page. Please reload to try again or contact web developer</h6>
                                                        </div>
                                                    </div>
                                            }
                                            <div>
                                                {
                                                    !errorMessage ?
                                                        <div className="category_display">
                                                            <h3 style={{ marginBottom: 0 }}>User Data</h3>
                                                            <Table
                                                                columns={userColumns}
                                                                dataSource={userGetData(userCurrent, userPageSize)}
                                                                bordered
                                                            />
                                                            <div style={{ marginTop: '3%' }}></div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <div className="page_center">
                                                                <img src={Thinking} alt="man thinking" />
                                                                <h6>Ooops! An error occurred while loading this page. Please reload to try again or contact web developer</h6>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default AllUserOrders;