import axios from 'axios';

const axiosCall = axios.create({
    // localhost:9000
    baseURL: 'https://backend.vo3designs.com/api/v1/furniture',
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
})

export default axiosCall;