import 'antd/dist/antd.css';
import './assets/css/index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ProtectedRoute from './utils/protectedRoutes';
import AuthProtectedRoute from './utils/AuthProtectedRoute';
import AppRoute from './utils/routes';

// import NewEvent from './components/events/new_event';
// import AllEvents from './components/events/allevents';
import NewCategory from './components/categories/add';
import AllCategories from './components/categories/all';
import NewProducts from './components/products/add';

import NewPortfolio from './components/portfolio/add';
import AllPortfolio from './components/portfolio/all';
import AddPortfolioImage from './components/portfolio/add-image';
import AllProducts from './components/products/all';
import AllUsers from './components/users/allusers';
import AllUserOrders from './components/products/allorders';
import SingleProduct from './components/products/order';
import AddProductImage from './components/products/add-image';
import Homepage from './components/homepage';
import SignIn from './components/auth/signin';
import SignOut from './components/auth/signout';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>

                <Route path="/signin" element={<AuthProtectedRoute><SignIn /></AuthProtectedRoute>} />
                <Route path="/signout" element={<ProtectedRoute><SignOut /></ProtectedRoute>} />

                <Route path="/" element={<ProtectedRoute><Homepage /> </ProtectedRoute>} />
                <Route path={AppRoute.all_users} element={<ProtectedRoute><AllUsers /> </ProtectedRoute>} />
                <Route path={AppRoute.all_category} element={<ProtectedRoute><AllCategories /> </ProtectedRoute>} />
                <Route path={AppRoute.new_category} element={<ProtectedRoute><NewCategory /> </ProtectedRoute>} />
                <Route path={AppRoute.all_category} element={<ProtectedRoute><AllCategories /> </ProtectedRoute>} />
                <Route path={AppRoute.new_products} element={<ProtectedRoute><NewProducts /> </ProtectedRoute>} />
                <Route path={AppRoute.all_products} element={<ProtectedRoute><AllProducts /> </ProtectedRoute>} />
                <Route path={`${AppRoute.add_product_image}/:id`} element={<ProtectedRoute><AddProductImage /> </ProtectedRoute>} />


                <Route path={AppRoute.all_orders} element={<ProtectedRoute><AllUserOrders /> </ProtectedRoute>} />
                <Route path={AppRoute.single_order} element={<ProtectedRoute><SingleProduct /> </ProtectedRoute>} />

                <Route path={AppRoute.add_portfolio} element={<ProtectedRoute><NewPortfolio /> </ProtectedRoute>} />
                <Route path={AppRoute.all_portfolio} element={<ProtectedRoute><AllPortfolio /> </ProtectedRoute>} />
                <Route path={`${AppRoute.add_portfolio_image}/:id`} element={<ProtectedRoute><AddPortfolioImage /> </ProtectedRoute>} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;