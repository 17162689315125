import React, { useState, useEffect } from 'react';
import SideNav from '../../utils/side_nav';
import TopNav from '../../utils/top_nav';
import { DateTime } from 'luxon';
import NumberFormat from 'react-number-format';
import { Input, Spin, message, Table, Tabs } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Controller, set, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Link } from 'react-router-dom';
import Thinking from '../../assets/images/think.svg';
import axiosCall from '../../utils/axiosCall';

const SingleProduct = () => {

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [videoDataMessage, setVideoDataMessage] = useState({});
    const [spinnerStatus, setSpinnerStatus] = useState(true);
    const [orderData, setOrderData] = useState([]);
    const searchParams = useParams();
    const [current] = useState(1);

    const navigate = useNavigate();

    const resolveForm = yup.object().shape({
        merchName: yup.string().required('Please enter course name'),
    })

    const { handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(resolveForm)
    });

    const resolveSenderForm = yup.object().shape({
        senderName: yup.string().required('Please enter sender name'),
        senderPhone: yup.string().required('Please enter sender phone number'),
    })

    const { handleSubmit: handleSenderInfoSubmit, control: controlSenderInfoSubmit,
        formState: { errors: senderInfoErrors }, setValue: setSenderInfoValue } = useForm({
            resolver: yupResolver(resolveSenderForm)
        });

    useEffect(() => {
        setErrorMessage('');
        const merchId = searchParams.orderId;
        axiosCall.get(`/products/order/${merchId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(allOrders => {
                if (allOrders.data.statusMessage === "success") {
                    setVideoDataMessage(allOrders.data.message);
                    setSpinnerStatus(false);
                    let { fullName, emailAddress, phoneNumber, state, localGovernment,
                        houseAddress, deliveryDate, transactionId, amountToPay, totalOrderPrice,
                        senderName, senderPhone, orderInformation } = allOrders.data.message;
                    setValue('fullName', fullName);
                    setValue('emailAddress', emailAddress);
                    setValue('phoneNumber', phoneNumber);
                    setValue('deliveryState', state);
                    setValue('deliveryLGA', localGovernment);
                    setValue('houseAddress', houseAddress);
                    setValue('deliveryDate', deliveryDate);
                    setValue('transactionId', transactionId);
                    setValue('amountToPay', `NGN ${amountToPay}`);
                    setValue('orderAmount', `NGN ${totalOrderPrice}`);
                    setSenderInfoValue('senderName', senderName);
                    setSenderInfoValue('senderPhone', senderPhone);
                    setValue('orderInformation', orderInformation);

                    setOrderData(allOrders.data.message.OrderedItems);
                } else {
                    setErrorMessage(allOrders.data.summary)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                setErrorMessage('An error occurred while fetching data. Please reload page to try again');
                setSpinnerStatus(false);
            })
    }, [])

    const setSenderInfo = e => {
        setErrorMessage('');
        setSpinnerStatus(true)
        axiosCall.post('/products/order/set-sender-data', {
            senderName: e.senderName,
            senderPhone: e.senderPhone,
            orderId: videoDataMessage.id
        })
            .then(categorySaved => {
                if (categorySaved.data.statusMessage === "success") {
                    navigate(0);
                } else {
                    setErrorMessage(categorySaved.data.summary)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                console.log(err)
                setErrorMessage('An error occurred while saving sending data. Please try again')
                setSpinnerStatus(false);
            })
    }

    const setOrderCompletion = () => {
        setSpinnerStatus(true)
        axiosCall.post('/products/order/change-order-completion', {
            transactionCompleted: !videoDataMessage.transactionCompleted ? true : false,
            deliveryStatus: !videoDataMessage.transactionCompleted ? 'delivered' : 'processing',
            orderId: videoDataMessage.id
        })
            .then(categorySaved => {
                if (categorySaved.data.statusMessage === "success") {
                    navigate(0);
                } else {
                    setErrorMessage(categorySaved.data.summary)
                    setSpinnerStatus(false);
                }
            })
            .catch(err => {
                setErrorMessage('An error occurred while saving category data. Please try again')
                setSpinnerStatus(false);
            })
    }

    const columns = [
        {
            title: '',
            dataIndex: 'sn'
        },
        {
            title: 'Product Image',
            className: 'column-money',
            dataIndex: 'productImage',
        },
        {
            title: 'Product Name',
            className: 'column-money',
            dataIndex: 'productName',
        },
        {
            title: 'Color',
            className: 'column-money',
            dataIndex: 'color',
        },
        {
            title: 'Quantity',
            className: 'column-money',
            dataIndex: 'quantity',
        }
    ];
    const data = orderData.map((order, index) => {
        return {
            sn: index + 1,
            key: index,
            productImage: <img className="orderImage" src={order.Product.productImage} alt={order.Product.productName} />,
            productName: order.Product.productName,
            color: order.color,
            quantity: order.quantity,
            deliveryStatus: order.deliveryStatus,
        }
    })

    const pageSize = 10;

    const getData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    return (
        <div>
            <Spin spinning={spinnerStatus}>
                <div className="portal_page">
                    <div>
                        <SideNav />
                    </div>
                    <div className="main_content">
                        <TopNav currentPage={"Order History"}
                            buttonTitle={"See all Products"}
                            buttonLink={"/products/all"}
                            currentPageInfo={"See and access all transaction history"} />
                        <div>
                            <div className="main_compartment">
                                <div className="main_compartment_content_display">
                                    <div className="main_compartment_content">
                                        <div className="form_display">
                                            <Tabs
                                                defaultActiveKey="1"
                                                type="card">
                                                <Tabs.TabPane tab="Update Delivery Status" key="1">
                                                    <form onSubmit={handleSubmit(() => console.log('done'))}>
                                                        <div>
                                                            <div className="form_heading_text">
                                                                <h3 className="big_site_text">Event Basic Info</h3>
                                                                <p>Help people in the area discover your event and let attendees know
                                                                    where to show up. Name your event and tell event-goers why they
                                                                    should come. Add details that highlight what makes it unique.</p>
                                                            </div>
                                                            <div>
                                                                {
                                                                    errorMessage ?
                                                                        <p className="errorMessage">{errorMessage}</p> : ''
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="fullName">User name</label>
                                                                <Controller control={control} defaultValue="" name="fullName"
                                                                    render={({ field }) => (
                                                                        <Input {...field} id="fullName" disabled
                                                                            type="text" style={{ height: '3rem' }} />
                                                                    )
                                                                    } />

                                                                {errors.fullName && <p className="errorMessage">{errors.fullName.message}</p>}
                                                            </div>
                                                            <div className="form_flex_2">
                                                                <div className="form-group">
                                                                    <label htmlFor="emailAddress">Email address</label>
                                                                    <Controller control={control} defaultValue="" name="emailAddress"
                                                                        render={({ field }) => (
                                                                            <Input {...field} id="emailAddress" disabled
                                                                                type="email" style={{ height: '3rem' }} />
                                                                        )
                                                                        } />

                                                                    {errors.emailAddress && <p className="errorMessage">{errors.emailAddress.message}</p>}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="phoneNumber">Phone number</label>
                                                                    <Controller control={control} defaultValue="" name="phoneNumber"
                                                                        render={({ field }) => (
                                                                            <Input {...field} id="phoneNumber" disabled
                                                                                type="email" style={{ height: '3rem' }} />
                                                                        )
                                                                        } />

                                                                    {errors.phoneNumber && <p className="errorMessage">{errors.phoneNumber.message}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="form_flex_2">
                                                                <div className="form-group">
                                                                    <label htmlFor="orderAmount">Order amount</label>
                                                                    <Controller control={control} defaultValue="" name="orderAmount"
                                                                        render={({ field }) => (
                                                                            <Input {...field} id="orderAmount" disabled
                                                                                type="email" style={{ height: '3rem' }} />
                                                                        )
                                                                        } />

                                                                    {errors.orderAmount && <p className="errorMessage">{errors.orderAmount.message}</p>}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="amountToPay">Amount to pay</label>
                                                                    <Controller control={control} defaultValue="" name="amountToPay"
                                                                        render={({ field }) => (
                                                                            <Input {...field} id="amountToPay" disabled
                                                                                type="text" style={{ height: '3rem' }} />
                                                                        )
                                                                        } />

                                                                    {errors.amountToPay && <p className="errorMessage">{errors.amountToPay.message}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="form_flex_2">
                                                                <div className="form-group">
                                                                    <label htmlFor="deliveryState">Delivery State</label>
                                                                    <Controller control={control} defaultValue="" name="deliveryState"
                                                                        render={({ field }) => (
                                                                            <Input {...field} id="deliveryState" disabled
                                                                                type="email" style={{ height: '3rem' }} />
                                                                        )
                                                                        } />

                                                                    {errors.deliveryState && <p className="errorMessage">{errors.deliveryState.message}</p>}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="deliveryLGA">Delivery LGA</label>
                                                                    <Controller control={control} defaultValue="" name="deliveryLGA"
                                                                        render={({ field }) => (
                                                                            <Input {...field} id="deliveryLGA" disabled
                                                                                type="text" style={{ height: '3rem' }} />
                                                                        )
                                                                        } />

                                                                    {errors.deliveryLGA && <p className="errorMessage">{errors.deliveryLGA.message}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="houseAddress">House address</label>
                                                                <Controller control={control} defaultValue="" name="houseAddress"
                                                                    render={({ field }) => (
                                                                        <Input.TextArea {...field} id="houseAddress" disabled
                                                                            type="text" style={{ height: '3rem' }} />
                                                                    )
                                                                    } />

                                                                {errors.houseAddress && <p className="errorMessage">{errors.houseAddress.message}</p>}
                                                            </div>
                                                            <div className="form_flex_2">
                                                                <div className="form-group">
                                                                    <label htmlFor="deliveryDate">Delivery Date</label>
                                                                    <Controller control={control} defaultValue="" name="deliveryDate"
                                                                        render={({ field }) => (
                                                                            <Input {...field} id="deliveryDate" disabled
                                                                                type="email" style={{ height: '3rem' }} />
                                                                        )
                                                                        } />

                                                                    {errors.deliveryDate && <p className="errorMessage">{errors.deliveryDate.message}</p>}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="transactionId">Transaction Id</label>
                                                                    <Controller control={control} defaultValue="" name="transactionId"
                                                                        render={({ field }) => (
                                                                            <Input {...field} id="transactionId" disabled
                                                                                type="text" style={{ height: '3rem' }} />
                                                                        )
                                                                        } />

                                                                    {errors.transactionId && <p className="errorMessage">{errors.transactionId.message}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="orderInformation">Order Information</label>
                                                                <Controller control={control} defaultValue="" name="orderInformation"
                                                                    render={({ field }) => (
                                                                        <Input {...field} id="orderInformation" disabled
                                                                            type="text" style={{ height: '3rem' }} />
                                                                    )
                                                                    } />

                                                                {errors.orderInformation && <p className="errorMessage">{errors.orderInformation.message}</p>}
                                                            </div>
                                                            <div>
                                                                <div style={{ marginTop: '5%' }}></div>
                                                                {
                                                                    !videoDataMessage.transactionCompleted ?
                                                                        <button
                                                                            onClick={setOrderCompletion}
                                                                            className="bg_green">Update as Complete</button>
                                                                        :
                                                                        <button
                                                                            onClick={setOrderCompletion}
                                                                            className="bg_red">Update as Incomplete</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </form>
                                                </Tabs.TabPane>
                                                <Tabs.TabPane tab="Update Sender Details" key="2">
                                                    <form onSubmit={handleSenderInfoSubmit(setSenderInfo)}>
                                                        <div>
                                                            <div className="form_heading_text">
                                                                <h3 className="big_site_text">Update Sender Details</h3>
                                                            </div>
                                                            <div>
                                                                {
                                                                    errorMessage ?
                                                                        <p className="errorMessage">{errorMessage}</p> : ''
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="senderName">Sender Name</label>
                                                                <Controller control={controlSenderInfoSubmit} defaultValue="" name="senderName"
                                                                    render={({ field }) => (
                                                                        <Input {...field} id="senderName"
                                                                            type="text" style={{ height: '3rem' }} />
                                                                    )
                                                                    } />

                                                                {senderInfoErrors.senderName && <p className="errorMessage">{senderInfoErrors.senderName.message}</p>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="senderPhone">Sender Phone Number</label>
                                                                <Controller control={controlSenderInfoSubmit} defaultValue="" name="senderPhone"
                                                                    render={({ field }) => (
                                                                        <Input {...field} id="senderPhone"
                                                                            type="tel" style={{ height: '3rem' }} />
                                                                    )
                                                                    } />

                                                                {senderInfoErrors.senderPhone && <p className="errorMessage">{senderInfoErrors.senderPhone.message}</p>}
                                                            </div>
                                                            <button
                                                                className="bg_green">Set Sender Info</button>
                                                        </div>
                                                    </form>
                                                </Tabs.TabPane>
                                                <Tabs.TabPane tab="Products" key="3">
                                                    <Table
                                                        columns={columns}
                                                        dataSource={getData(current, pageSize)}
                                                        bordered
                                                    />
                                                </Tabs.TabPane>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default SingleProduct;