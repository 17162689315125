import React, { useState, useEffect } from 'react';

import { Spin, Table } from 'antd';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import NumberFormat from 'react-number-format';
import AppRoute from '../../utils/routes';

import SideNav from '../../utils/side_nav';
import TopNav from '../../utils/top_nav';
import axios from '../../utils/axiosCall';

const AllProducts = () => {

    const [productData, setProductData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [current] = useState(1);

    const [errorMessage, setErrorMessage] = useState('');
    const [spinnerStatus, setSpinnerStatus] = useState(true);

    useEffect(() => {
        axios('/products/all')
            .then(productData => {
                if (productData.data.statusMessage === "success") {
                    setProductData(productData.data.message);
                    setSpinnerStatus(false);
                    setErrorMessage('');
                    setLoadingData(false);
                } else {
                    setErrorMessage(productData.data.summary)
                    setSpinnerStatus(false);
                    setLoadingData(false);
                }
            })
            .catch(err => {
                setErrorMessage('An error occurred while fetching category data. Please reload page to try again');
                setSpinnerStatus(false);
                setLoadingData(false);
            })
    }, [])

    return (
        <div>
            <Spin spinning={spinnerStatus}>
                <div className="portal_page">
                    <div>
                        <SideNav />
                    </div>
                    <div className="main_content">
                        <TopNav currentPage={"All Products"}
                            buttonTitle={"Add New Product"}
                            buttonLink={"/products/add"}
                            currentPageInfo={"See All Products"} />
                        <div>
                            <div className="main_compartment">
                                <div className="main_compartment_content_display">
                                    <div className="main_compartment_content">
                                        <div className="contain">
                                            {
                                                !loadingData ?
                                                    !errorMessage ?
                                                        <div className="grid_4 products-grid">
                                                            {
                                                                productData.map((products, index) => (
                                                                    <div key={index}>
                                                                        <div>
                                                                            <div className="product-images">
                                                                                <img src={products.productImage} alt="extra_1" />
                                                                            </div>
                                                                            <h4 className="furniture_name">{products.productName}</h4>
                                                                            <p className="furniture_amount"><span className="furniture_amount_currency">NGN </span>
                                                                                <span className="new_product_amount"><NumberFormat
                                                                                    value={(+products.price).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span>
                                                                            </p>
                                                                            <Link className="bg_green"
                                                                                to={`${AppRoute.add_product_image}/${products.id}`}>Add Product Images</Link>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        :
                                                        <div className="bigErrorData">
                                                            <div>{errorMessage}</div>
                                                        </div>
                                                    :
                                                    <div>
                                                        <p>Hello</p>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default AllProducts;